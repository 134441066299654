import * as Sentry from '@sentry/nuxt'

Sentry.init({
  dsn: 'https://f25fc6dc76c756b5885432d217910319@o4504306684395520.ingest.us.sentry.io/4507940999004160',
  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    })
  ],
  profilesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 0.1,
});
